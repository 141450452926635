<template>
  <div class="page sonuc">
    <div class="formcontainer">
      <div class="formbox">
        <h2>Başvuru Güncelleme</h2>

        <!-- <div class="formhead">
          <div>
            Bu sayfada hesap yöneticilerimizin kontrol edeceği belgeler ve başvuru bilgileriniz yer
            almaktadır.
          </div>
        </div>-->

        <div class="durumbox">
          <div class="text">
            <div v-if="SUBMITTED || EDITED" class="details">
              <h4 class="yello_d">Onay Bekliyor</h4>
            </div>
            <div v-if="CONFIRMED" class="details">
              <h4>Onaylandı</h4>
            </div>
            <div class="details">
              <h4 class="red" v-if="REJECTED">
                <img src="@/assets/img/ico_exclamination.svg" alt />İşlem
                Bekleniyor:
              </h4>
              <div v-if="REJECTED && rejectedFiles.length > 0">
                <p v-for="(file, index) in rejectedFiles" :key="index">
                  <!-- ({{ index + 1 }})-->
                  {{ belge_turu_map[file.docType].str }}
                  <b>"{{ file.fileName }}"</b>:
                  <span style="color: red">{{ file.rejectionReason }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>

        <bilgi-boxes></bilgi-boxes>

        <div v-if="application.appType !== 'EDEVLET'" class="formpart">
          <div class="formfull">
            <h3>Taahhütname</h3>

            <div class="filerow formpart filekt">
              <div
                v-for="(file, index) in sorted_fileSet"
                :key="index"
                class="fileline"
                :class="file_status_map[file.fileStatus].box_class"
              >
                <img src="@/assets/img/file_pdf.svg" alt />
                <div class="filetype">
                  {{ belge_turu_map[file.docType].str }}
                </div>

                <div class="filename">{{ file.fileName }}</div>

                <label
                  v-if="!REJECTED"
                  class="changebtn"
                  :style="file_status_map[file.fileStatus].style"
                  :for="'degistir_' + file.id"
                  >{{ file_status_map[file.fileStatus].label }}</label
                >

                <div v-else class="durum">
                  <div>
                    <input
                      :type="file_status_map[file.fileStatus].type"
                      :id="'degistir_' + file.id"
                      name="#"
                      value
                      class="inputfile"
                      accept=".pdf"
                      @change="FILE_DEGISTIR_CHANGED(file, $event)"
                    />
                    <label
                      class="changebtn"
                      :style="file_status_map[file.fileStatus].style"
                      :for="'degistir_' + file.id"
                      >{{ file_status_map[file.fileStatus].label }}</label
                    >
                  </div>

                  <!-- <div class="ico">
                    <img :src="file_status_map[file.fileStatus].icon" alt />
                  </div>-->
                </div>
              </div>

              <div class="fileline" v-if="REJECTED">
                <div class="text">
                  Eğer tekrar imzalamak üzere adınıza hazırlanmış
                  <br />
                  <b>Kullanım Taahhütnamesi</b>'ni kaybettiyseniz tekrar
                  indirebilirsiniz:
                </div>

                <a
                  @click="TRACK('Belge_Indir')"
                  download
                  :href="`${BFF}/hspreapp/file/download/hizmet-sozlesmesi/${application.appNo}`"
                  class="downloadsonuc"
                  target="_blank"
                >
                  <img src="@/assets/img/ico_download.svg" alt />Kullanım
                  Taahhütnamesi'ni İndir
                </a>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="formpart">
          <div class="formfull">
            <h3>Belgeler</h3>

            <div class="filerow formpart">
              <div
                v-for="(file, index) in sorted_fileSet"
                :key="index"
                class="fileline"
                :class="file_status_map[file.fileStatus].box_class"
              >
                <div class="filetype">
                  {{ index + 1 }}-{{ belge_turu_map[file.docType].str }}
                </div>
                <img src="@/assets/img/file_pdf.svg" alt />
                <div class="filename">{{ file.fileName }}</div>
                <label
                  v-if="SUBMITTED"
                  class="changebtn"
                  :style="file_status_map[file.fileStatus].style"
                  :for="'degistir_' + file.id"
                  >{{ file_status_map[file.fileStatus].label }}</label
                >

                <div class="durum">
                  <div v-if="REJECTED">
                    <input
                      :type="file_status_map[file.fileStatus].type"
                      :id="'degistir_' + file.id"
                      name="#"
                      value
                      class="inputfile"
                      accept=".pdf"
                      @change="FILE_DEGISTIR_CHANGED(file, $event)"
                    />
                    <label
                      class="changebtn"
                      :style="file_status_map[file.fileStatus].style"
                      :for="'degistir_' + file.id"
                      >{{ file_status_map[file.fileStatus].label }}</label
                    >
                  </div>

                  <div class="ico">
                    <img :src="file_status_map[file.fileStatus].icon" alt />
                  </div>
                </div>
              </div>

              <div class="fileline" v-if="REJECTED">
                <div class="text">
                  Eğer tekrar imzalamak üzere adınıza hazırlanmış
                  <br />
                  <b>Kullanım Taahhütnamesi</b>'ni kaybettiyseniz tekrar
                  indirebilirsiniz:
                </div>

                <a
                  @click="TRACK('Belge_Indir')"
                  download
                  :href="`${BFF}/hspreapp/file/download/hizmet-sozlesmesi/${application.appNo}`"
                  class="downloadsonuc"
                  target="_blank"
                >
                  <img src="@/assets/img/ico_download.svg" alt />Kullanım
                  Taahhütnamesi'ni İndir
                </a>
              </div>
            </div>
          </div>
        </div> -->

        <div v-if="application.brandSet && application.brandSet.length > 0">
          <div
            class="izintable"
            v-for="(item, index) in getBrands"
            :key="index"
          >
            <div class="plr">
              <form>
                <h3 v-if="index === 0">Marka Bilgileri</h3>
                <div class="izinline">
                  <marka-show :brand="item" :editButtons="false" />
                </div>
              </form>
            </div>
          </div>
        </div>

        <sonraki-bar
          :dsbl="!next"
          plabel
          :label="label"
          @next="next ? NEXT() : null"
          :friction="200"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
import MarkaShow from "./genel/MarkaShow";
import SonrakiBar from "./genel/SonrakiBar";
import BilgiBoxes from "./genel/BilgiBoxes";

export default {
  name: "ZSonucDetay",
  components: {
    BilgiBoxes,
    SonrakiBar,
    MarkaShow,
  },

  data() {
    return {
      BFF: process.env.VUE_APP_BFF_API,
      belge_turu_map: require("../../../misc/enums").ENUM_BELGE_TURU,
      file_status_map: require("../../../misc/enums").ENUM_BELGE_STATUS_INFO,
    };
  },

  mounted() {
    this.BELGE_LISTESI_UPDATE();
  },

  computed: {
    ...mapState("applicant", ["application"]),
    ...mapGetters("applicant", ["getBrands"]),

    next() {
      return this.rejectedFiles.length === 0;
    },

    rejectedFiles() {
      return this.application.fileSet.filter((e) => e.fileStatus == "REJECTED");
    },

    SUBMITTED() {
      return this.application.step === "INITIAL_SUBMITTED_TO_AGENT";
    },

    REJECTED() {
      return this.application.step === "FINAL_AGENT_REJECTED";
    },

    CONFIRMED() {
      return this.application.step === "FINAL_AGENT_CONFIRMED";
    },

    EDITED() {
      return this.application.step === "FINAL_DOCUMENTS_EDITED";
    },

    label() {
      if (this.SUBMITTED || this.CONFIRMED) return "Başvuru Durumu";
      if (this.REJECTED) return "Onay İçin Gönder";
    },

    EDEVLET() {
      return this.application.appType === "EDEVLET";
    },

    EIMZA() {
      return this.application.appType === "EIMZA";
    },

    sorted_fileSet() {
      let files = this.application.fileSet.sort((a, b) =>
        this.belge_turu_map[a.docType].order >
        this.belge_turu_map[b.docType].order
          ? 1
          : -1
      );
      if (this.EDEVLET) {
        files = files.filter((e) => e.docType !== "HIZMET_SOZLESMESI");
      }
      if (this.EIMZA) {
        files = files.filter((e) => e.docType === "HIZMET_SOZLESMESI");
      }
      return files;
    },
  },

  methods: {
    ...mapActions("applicant", [
      "belge_yukle",
      "belge_listesi_cek",
      "belgeleri_tekrar_onayla",
    ]),

    ...mapMutations("applicant", ["SET_APPLICATION"]),

    TRACK(name) {
      // _paq.push(["trackEvent", "Basvuru", name, "Success"]);
    },

    BELGE_LISTESI_UPDATE() {
      this.belge_listesi_cek().then((res) => {
        this.SET_APPLICATION(res.payload);
        this.$nextTick(() => {
          if (this.CONFIRMED) {
            // this.$router.push("/hizmet-saglayici/basvuru/sonuc-on-basvuru");
            this.$router.push("/hizmet-saglayici/basvuru/sonuc");
          }
          if (this.REJECTED || this.EDITED) {
            this.$router.push("/hizmet-saglayici/basvuru/bilgi-girisi");
          }
        });
      });
    },

    FILE_DEGISTIR_CHANGED(file, e) {
      this.belge_yukle({
        path: "file/hizmet-sozlesmesi",
        file: e.target.files[0],
      }).then((res) => {
        this.SET_APPLICATION(res.payload);
        this.BELGE_LISTESI_UPDATE();
        e.target.value = "";
      });
    },

    NEXT() {
      this.belge_listesi_cek().then((res) => {
        this.SET_APPLICATION(res.payload);
        this.$router.push("/hizmet-saglayici/basvuru/sonuc-on-basvuru");
      });

      // if (this.REJECTED) {
      //   this.belgeleri_tekrar_onayla().then((res) => {
      //     this.SET_APPLICATION(res.payload);
      //     this.$router.push("/hizmet-saglayici/basvuru/sonuc-on-basvuru");
      //   });
      // } else {
      //   this.belge_listesi_cek().then((res) => {
      //     // TODO: Burada app stateini update icin kullaniliyor. normalde getAppState diye bil yol olsa daha iyi
      //     this.SET_APPLICATION(res.payload);
      //     this.$router.push("/hizmet-saglayici/basvuru/sonuc-on-basvuru");
      //   });
      // }
    },
  },
};
</script>

<style>
.formfull .filerow .fileline {
  width: 47%;
}

.downloadheader {
  display: flex;
  justify-content: space-between;
}
.downloadsonuc {
  display: flex;
  color: #4da0df;
  align-items: center;
  background: #fff;
  border-radius: 0.5vw;
  padding: 1vw;
  border: 1px solid #4da0df;
  margin: 4vh;
}
.downloadsonuc:hover {
  background: #e0f1ff;
}
.downloadsonuc img {
  height: 24px;
  margin-right: 5px;
}

h4.red {
  display: flex;
  align-items: baseline;
  margin-bottom: 1vw;
  /* background: #eabdbd;  */
  padding: 0.5vw;
  border-radius: 0.5vw;
  color: #ce2f27;
}

h4.red img {
  margin-right: 6px;
}

.durumbox {
  background: #eee;
  border-radius: 0.5vw;
  padding: 1.5vw;
  margin: 2vw 0;
}
.durumbox h3 {
  margin-bottom: 1vw;
}
.durumbox .details p {
  margin-left: 2vw;
}

/*  on phones ****************************************************************************************************************************************************************/

@media screen and (max-aspect-ratio: 13/9) and (max-width: 500px) {
  .downloadheader {
    flex-wrap: wrap;
  }
  .downloadsonuc {
    border-radius: 2vw;
    padding: 3vw;
    margin-bottom: 5vw;
    width: 100%;
  }
}
</style>
